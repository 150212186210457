class Common {
  // a: [
  //   '',
  //   'one ',
  //   'two ',
  //   'three ',
  //   'four ',
  //   'five ',
  //   'six ',
  //   'seven ',
  //   'eight ',
  //   'nine ',
  //   'ten ',
  //   'eleven ',
  //   'twelve ',
  //   'thirteen ',
  //   'fourteen ',
  //   'fifteen ',
  //   'sixteen ',
  //   'seventeen ',
  //   'eighteen ',
  //   'nineteen '
  // ],
  // b: [
  //   '',
  //   '',
  //   'twenty',
  //   'thirty',
  //   'forty',
  //   'fifty',
  //   'sixty',
  //   'seventy',
  //   'eighty',
  //   'ninety'
  // ],
  // getUniqueId () {
  //   return '_' + Math.random().toString(36).substr(2, 9)
  // },
  // convertNumberToWords (amount) {
  //   let words = []
  //   words[0] = ''
  //   words[1] = 'One'
  //   words[2] = 'Two'
  //   words[3] = 'Three'
  //   words[4] = 'Four'
  //   words[5] = 'Five'
  //   words[6] = 'Six'
  //   words[7] = 'Seven'
  //   words[8] = 'Eight'
  //   words[9] = 'Nine'
  //   words[10] = 'Ten'
  //   words[11] = 'Eleven'
  //   words[12] = 'Twelve'
  //   words[13] = 'Thirteen'
  //   words[14] = 'Fourteen'
  //   words[15] = 'Fifteen'
  //   words[16] = 'Sixteen'
  //   words[17] = 'Seventeen'
  //   words[18] = 'Eighteen'
  //   words[19] = 'Nineteen'
  //   words[20] = 'Twenty'
  //   words[30] = 'Thirty'
  //   words[40] = 'Forty'
  //   words[50] = 'Fifty'
  //   words[60] = 'Sixty'
  //   words[70] = 'Seventy'
  //   words[80] = 'Eighty'
  //   words[90] = 'Ninety'
  //   amount = amount.toString()
  //   let atemp = amount.split('.')
  //   let number = atemp[0].split(',').join('')
  //   let nlength = number.length
  //   let wordsstring = ''
  //   if (nlength <= 9) {
  //     let narray = [0, 0, 0, 0, 0, 0, 0, 0, 0]
  //     let receivednarray = []
  //     for (let i = 0; i < nlength; i++) {
  //       receivednarray[i] = number.substr(i, 1)
  //     }
  //     for (let i = 9 - nlength, j = 0; i < 9; i++, j++) {
  //       narray[i] = receivednarray[j]
  //     }
  //     for (let i = 0, j = 1; i < 9; i++, j++) {
  //       if (i === 0 || i === 2 || i === 4 || i === 7) {
  //         if (narray[i] === 1) {
  //           narray[j] = 10 + parseInt(narray[j])
  //           narray[i] = 0
  //         }
  //       }
  //     }
  //     let value = ''
  //     for (let i = 0; i < 9; i++) {
  //       if (i === 0 || i === 2 || i === 4 || i === 7) {
  //         value = narray[i] * 10
  //       } else {
  //         value = narray[i]
  //       }
  //       if (value !== 0) {
  //         wordsstring += words[value] + ' '
  //       }
  //       if ((i === 1 && value !== 0) || (i === 0 && value !== 0 && narray[i + 1] === 0)) {
  //         wordsstring += 'Million '
  //       }
  //       if ((i === 3 && value !== 0) || (i === 2 && value !== 0 && narray[i + 1] === 0)) {
  //         wordsstring += 'Hundred Thousand '
  //       }
  //       if ((i === 5 && value !== 0) || (i === 4 && value !== 0 && narray[i + 1] === 0)) {
  //         wordsstring += 'Thousand '
  //       }
  //       if (i === 6 && value !== 0 && (narray[i + 1] !== 0 && narray[i + 2] !== 0)) {
  //         wordsstring += 'Hundred and '
  //       } else if (i === 6 && value !== 0) {
  //         wordsstring += 'Hundred '
  //       }
  //     }
  //     wordsstring = wordsstring.split('  ').join(' ')
  //   }
  //   return wordsstring
  // },
  // convertNumberWithDecimal (number) {
  //   let nums = number.toString().split('.')
  //   var whole = this.convertNumberToWords(nums[0])
  //   if (nums.length === 2) {
  //     let fraction = this.convertNumberToWords(nums[1])
  //     whole = whole.replace('and ', '')
  //     return (whole + 'and ' + fraction + 'ONLY').toUpperCase()
  //   } else {
  //     return (whole + 'ONLY').toUpperCase()
  //   }
  // },
  // round (value, precision) {
  //   if (value === undefined) {
  //     return undefined
  //   }
  //   var multiplier = Math.pow(10, precision || 0)
  //   return (Math.round(value * multiplier) / multiplier).toFixed(precision)
  // },
  // dateString (date) {
  //   return date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2)
  // }

  static randomString (length = 8) {
    var text = ''
    var possible = 'ABCDEF0123456789' // GHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz
    for (var i = 0; i < length; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length))
    }
    return text
  }

  static forceError () {
    let error = new Error('forced error')
    try {
      let test = true
      if (test) {
        throw error
      }
    } catch (error) {
      console.error('-- forced error', error)
    }
  }

  static loadImage (url) {
    return new Promise((resolve, reject) => {
      let img = new Image()
      img.crossOrigin = 'anonymous' // CORS fix 1 *
      // let isUrl = false
      // if (url.indexOf('http://') >= 0) {
      //   isUrl = true
      //   url = url.replace('http://', '//') // CORS fix 2
      // }
      // if (url.indexOf('https://') >= 0) {
      //   isUrl = true
      //   url = url.replace('https://', '//') // CORS fix 2
      // }
      if (url.indexOf('http://') >= 0 || url.indexOf('https://') >= 0) {
        url += `?d=${Common.randomString()}` // CORS fix 3
        // console.log(`load image: ${url}`)
      }
      // another fix in S3 CORS:
      // <AllowedOrigin>http://*</AllowedOrigin>
      // <AllowedOrigin>https://*</AllowedOrigin>
      img.onerror = (error, info) => {
        console.log(`common: load image (${url}) error`, { error, info })
        reject(new Error(`could not load image: ${url}`))
      }
      img.onload = () => {
        // if (mime !== undefined && mime.length > 0) {
        //   var canvas = document.createElement('canvas')
        //   canvas.width = img.width
        //   canvas.height = img.height
        //   var context = canvas.getContext('2d')
        //   context.drawImage(img, 0, 0)
        //   var dataURL = canvas.toDataURL(mime)
        //   return resolve(dataURL)
        // }
        return resolve(img)
      }
      img.src = url
    })
  }

  static getGap (text) {
    if (text === '') {
      return 4
    } else {
      var length = text.length
      var line = Math.ceil(length / 150)
      return line * 4 + 4
    }
  }

  static shortDescription (value) {
    if (value !== undefined) {
      if (value.length > 35) {
        return value.substring(0, 35) + '...'
      } else {
        return value
      }
    }
  }

  static async timeout (ms) {
    return new Promise(resolve => setTimeout(resolve, ms))
  }

  // static objKeys (dotkey) {
  //   let keys = dotkey.split('.')
  //   let keyFirst = dotkey.split('.')
  //   let keyLast = keyFirst.pop()
  //   keyFirst = keyFirst.join('.')
  //   return {
  //     keys,
  //     keyFirst,
  //     keyLast
  //   }
  // }
  //
  // static objGet (object, dotkey) {
  //   const getProp = (obj, prop) => {
  //     return prop.split('.').reduce((r, e) => {
  //       if (r === undefined || r === null) {
  //         return undefined
  //       }
  //       return r[e]
  //     }, obj)
  //   }
  //   return getProp(object, dotkey)
  // }
  //
  // static objExcludeKeys (object, excludeKeys) {
  //   for (let k in excludeKeys) {
  //     let key = excludeKeys[k]
  //     if (object.hasOwnProperty(key)) {
  //       delete object[key]
  //     }
  //   }
  //   return object
  // }

  static hexToRgb (hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : { r: 0, g: 0, b: 0 }
  }

  static componentToHex (c) {
    var hex = c.toString(16)
    return hex.length === 1 ? '0' + hex : hex
  }

  static rgbToHex (r, g, b) {
    return '#' + Common.componentToHex(r) + Common.componentToHex(g) + Common.componentToHex(b)
  }

  // static objGet2 (object, dotkey) {
  //   if (!object || !dotkey || dotkey.length <= 0) return undefined
  //   let prop = dotkey.split('.')
  //   let obj = object
  //   for (let p in prop) {
  //     let pr = prop[p]
  //     if (object[pr] === undefined || object[pr] === null) {
  //       return undefined
  //     }
  //     obj = object[pr]
  //   }
  //   return obj
  // }
}

export default Common
